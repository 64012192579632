export const CAN_VIEW_IMAGE_CATEGORY = 'canViewImageCategory';
export const CAN_ADD_IMAGE_CATEGORY = 'canAddImageCategory';
export const CAN_EDIT_IMAGE_CATEGORY = 'canEditImageCategory';
export const CAN_ACTIVE_DEACTIVE_IMAGECATEGORY =
  'canActive/DeactivateImageCategory';
export const CAN_ADD_CONTAINER_IMAGES = 'canAddTransitionImages';
export const CAN_EDIT_CONTAINER_IMAGES = 'canEditTransitionImages';
export const CAN_DELETE_CONTAINER_IMAGES = 'canDeleteTransitionImages';
export const CAN_VIEW_CONTAINER_IMAGES = 'canViewTransitionImages';
export const CAN_VIEW_SHIPPING_LINE_STATUS = 'canViewShippingLineStatus';
export const CAN_ADD_IMAGE_TYPE = 'addImageType';
export const CAN_EDIT_IMAGE_TYPE = 'editImageType';
export const CAN_ADD_FIRM = 'canAddFirm';
export const CAN_VIEW_FIRM = 'canViewFirm';
export const CAN_EDIT_FIRM = 'canEditFirm';
export const CAN_DEACTIVATE_FIRM = 'canDeactivateFirm';
export const CAN_VIEW_CUSTOMER = 'canViewCustomer';
export const CAN_ADD_CUSTOMER = 'canAddCustomer';
export const CAN_EDIT_CUSTOMER = 'canEditCustomer';
export const CAN_ACTIVE_INACTIVE_CUSTOMER = 'canActiveInactiveCustomer';
export const CAN_VIEW_TRANSPORTER = 'canViewTransporter';
export const CAN_ADD_TRANSPORTER = 'canAddTransporter';
export const CAN_EDIT_TRANSPORTER = 'canEditTransporter';
export const CAN_ACTIVE_INACTIVE_TRANSPORTER = 'canActiveInactiveTransporter';
export const CAN_ADD_DRIVER = 'canAddDriver';
export const CAN_VIEW_DRIVER = 'canViewDriver';
export const CAN_EDIT_DRIVER = 'canEditDriver';
export const CAN_CHANGE_DRIVER_TRANSPORTER = 'canChangeDriverTransporter';
export const CAN_DEACTIVATE_DRIVER = 'canDeactivateDriver';
export const CAN_VIEW_DRIVER_DASHBOARD = 'canViewDriverDashboard';
export const CAN_ASSIGN_TRUCK = 'canAssignTruck';
export const CAN_DEALLOCATE_TRUCK = 'canDeallocateTruck';
export const CAN_ADD_TRUCK = 'canAddTruck';
export const CAN_VIEW_TRUCK = 'canViewTruck';
export const CAN_EDIT_TRUCK = 'canEditTruck';
export const CAN_CHANGE_TRUCK_TRANSPORTER = 'canChangeTruckTransporter';
export const CAN_DEACTIVATE_TRUCK = 'canDeactivateTruck';
export const CAN_VIEW_TRUCK_DASHBOARD = 'canViewTruckDashboard';
export const CAN_ASSIGN_DRIVER = 'canAssignDriver';
export const CAN_DEALLOCATE_DRIVER = 'canDeallocateDriver';
export const CAN_ADD_VESSEL_AND_VOYAGE = 'canAddVesselAndVoyage';
export const CAN_VIEW_VESSEL_AND_VOYAGE = 'canViewVesselAndVoyage';
export const CAN_EDIT_VESSEL_AND_VOYAGE = 'canEditVesselAndVoyage';
export const CAN_DEACTIVATE_VESSEL_AND_VOYAGE = 'canDeactivateVesselAndVoyage';
export const CAN_ADD_ARRIVAL_INFORMATION = 'canAddArrivalInformation';
export const CAN_VIEW_ARRIVAL_INFORMATION = 'canViewArrivalInformation';
export const CAN_EDIT_ARRIVAL_INFORMATION = 'canEditArrivalInformation';
export const CAN_DEACTIVATE_ARRIVAL_INFORMATION =
  'canDeactivateArrivalInformation';
export const CAN_ADD_CONTAINER = 'canAddContainer';
export const CAN_VIEW_CONTAINER = 'canViewContainer';
export const CAN_EDIT_CONTAINER = 'canEditContainer';
export const CAN_VIEW_CONTAINER_STATUS_LOGS = 'canViewContainerStatusLogs';
export const CAN_VIEW_JOB_NUMBER = 'canViewJobNo';
export const CAN_DEACTIVATE_CONTAINER = 'canDeactivateContainer';
export const CAN_ADD_EIRIN = 'canAddEIRIn';
export const CAN_VIEW_EIRIN = 'canViewEIRIn';
export const CAN_EDIT_EIRIN = 'canEditEIRIn';
export const CAN_ADD_VEHICLE_IN = 'canAddVehicleIn';
export const CAN_VIEW_VEHICLE_IN = 'canViewVehicleIn';
export const CAN_EDIT_VEHICLE_IN = 'canEditVehicleIn';
export const CAN_REVERT_VEHICLE_IN = 'canRevertVehicleIn';
export const CAN_ADD_VEHICLE_OUT = 'canAddVehicleOut';
export const CAN_VIEW_VEHICLE_OUT = 'canViewVehicleOut';
export const CAN_EDIT_VEHICLE_OUT = 'canEditVehicleOut';
export const CAN_PRINT_VEHICLE_OUT = 'canPrintVehicleOut';
export const CAN_REVERT_VEHICLE_OUT = 'canRevertVehicleOut';
export const CAN_ADD_VEHICLE_OUTSIDE_OUT = 'canAddVehicleOutsideOut';
export const CAN_VIEW_VEHICLE_OUTSIDE_OUT = 'canViewVehicleOutsideOut';
export const CAN_EDIT_VEHICLE_OUTSIDE_OUT = 'canEditVehicleOutsideOut';
export const CAN_PRINT_VEHICLE_OUTSIDE_OUT = 'canPrintVehicleOutsideOut';
export const CAN_REVERT_VEHICLE_OUTSIDE_OUT = 'canRevertVehicleOutsideOut';
export const CAN_VIEW_VEHICLE_PICKUP_SLIP = 'canViewVehiclePickupSlip';
export const CAN_ADD_VEHICLE_PICKUP_SLIP = 'canAddVehiclePickupSlip';
export const CAN_PRINT_VEHICLE_PICKUP_SLIP = 'canPrintVehiclePickupSlip';
export const CAN_REVERT_VEHICLE_PICKUP_SLIP = 'canRevertVehiclePickupSlip';
export const CAN_DEACTIVATE_EIRIN = 'canDeactivateEIRIn';
export const CAN_PRINT_EIRIN = 'canPrintEirIn';
export const CAN_MARK_TRIP = 'canMarkTrip';
export const CAN_VIEW_SERVICE_EIRIN = 'canViewServiceEirIn';
export const CAN_ADD_IMAGES = 'canAddImages';
export const CAN_ADD_EIROUT = 'canAddEIROut';
export const CAN_VIEW_EIROUT = 'canViewEIROut';
export const CAN_EDIT_EIROUT = 'canEditEIROut';
export const CAN_DEACTIVATE_EIROUT = 'canDeactivateEIROut';
export const CAN_PRINT_EIROUT = 'canPrintEirOut';
export const CAN_REVERT_EIROUT = 'canRevertEirOut';
export const CAN_ADD_EIROUT_IMAGES = 'canAddEirOutImages';
export const CAN_ADD_USER = 'canAddUser';
export const CAN_VIEW_USER = 'canViewUser';
export const CAN_EDIT_USER = 'canEditUser';
export const CAN_DELETE_USER = 'canDeleteUser';
export const CAN_DEACTIVATE_USER = 'canDeactivateUser';
export const CAN_VIEW_LOGIN_TOKEN = 'canViewLoginToken';
export const CAN_ADD_ROLE = 'canAddRole';
export const CAN_VIEW_ROLE = 'canViewRole';
export const CAN_EDIT_ROLE = 'canEditRole';
export const CAN_DELETE_ROLE = 'canDeleteRole';
export const CAN_DEACTIVATE_ROLE = 'canDeactivateRole';
export const CAN_VIEW_ROLE_DASHBOARD = 'canViewRoleDashboard';
export const CAN_VIEW_DEFAULT_SERVICE_CHARGES = 'canViewDefaultServiceCharges';
export const CAN_EDIT_DEFAULT_SERVICE_CHARGES = 'canEditDefaultServiceCharges';
export const CAN_ADD_CRO = 'canAddCRO';
export const CAN_VIEW_CRO = 'canViewCRO';
export const CAN_EDIT_CRO = 'canEditCRO';
export const CAN_CHANGE_CRO_TYPE = 'canChangeCroType';

export const CAN_DEACTIVATE_CRO = 'canDeactivateCRO';
export const CAN_BLOCK_UNBLOCK_CRO = 'canBlockUnblockCRO';
export const CAN_ADD_SERVICE = 'canAddService';
export const CAN_VIEW_SERVICE = 'canViewService';
export const CAN_EDIT_SERVICE = 'canEditService';
export const CAN_DEACTIVATE_SERVICE = 'canDeactivateService';
export const CAN_VIEW_REPAIR_ESTIMATE = 'canViewRepairEstimate';
export const CAN_ADD_EDIT_SERVICE = 'canAddEditService';
export const CAN_APPROVE_REJECT = 'canApproveReject';
export const CAN_ADD_CONTAINER_IMAGE = 'canAddContainerImage';
export const CAN_ADD_SERVICE_TO_READY_CONTAINER =
  'canAddServiceToReadyContainer';
export const CAN_VIEW_SERVICE_MANAGEMENT = 'canViewManagementPermission';
export const CAN_MARK_AS_READY = 'canMarkAsReady';
export const CAN_CHANGE_SERVICE_STATUS = 'canChangeServiceStatus';
export const CAN_ADD_SERVICES = 'canAddServices';
export const CAN_VIEW_GENERAL_SERVICE = 'canViewGeneralService';
export const CAN_ADD_EDIT_GENERAL_SERVICE = 'canAddEditGeneralService';
export const CAN_ACTIVATE_DEACTIVATE_GENERAL_SERVICE =
  'canActivateDeactivateGeneralService';
export const CAN_VIEW_REPAIR_SERVICE = 'canViewRepairService';
export const CAN_ADD_EDIT_REPAIR_SERVICE = 'canAddEditRepairService';
export const CAN_ACTIVATE_DEACTIVATE_REPAIR_SERVICE =
  'canActivateDeactivateRepairService';
export const CAN_VIEW_STORAGE_SERVICE = 'canViewStorageService';
export const CAN_ADD_EDIT_STORAGE_SERVICE = 'canAddEditStorageService';
export const CAN_ACTIVATE_DEACTIVATE_STORAGE_SERVICE =
  'canActivateDeactivateStorageService';
export const CAN_VIEW_ADDITIONAL_SERVICE = 'canViewAdditionalService';
export const CAN_ADD_REMOVE_SERVICE = 'canAddRemoveAdditionalService';
export const CAN_ADD_CITY = 'canAddCity';
export const CAN_VIEW_PERMISSION = 'canViewPermission';
export const CAN_START_SERVICE = 'canStartService';
export const CAN_MANAGE_SERVICE = 'canManageService';
export const CAN_VIEW_CHARGES = 'canViewCharges';
export const CAN_VIEW_CONTAINER_TYPE = 'canViewContainerType';
export const CAN_ADD_CONTAINER_TYPE = 'canAddContainerType';
export const CAN_EDIT_CONTAINER_TYPE = 'canEditContainerType';
export const CAN_DEACTIVATE_CONTAINER_TYPE = 'canDeactivateContainerType';
export const CAN_VIEW_EIRIN_REPORT = 'canViewEIRInReport';
export const CAN_QUICK_BIND_TRUCK_DRIVER_EIRIN = 'canEirInQuickBindTruckDriver';
export const CAN_VIEW_EIROUT_REPORT = 'canViewEIROutReport';
export const CAN_VIEW_PICKUP_SLIP = 'canViewpickupSlip';
export const CAN_ADD_PICKUP_SLIP = 'canAddPickupSlip';
export const CAN_EDIT_PICKUP_SLIP = 'canEditPickupSlip';
export const CAN_EIROUT_PICKUP_SLIP = 'canEirOutPickupSlip';
export const CAN_PRINT_PICKUP_SLIP = 'canPrintPickupSlip';
export const CAN_CANCEL_PICKUP_SLIP = 'canCancelPickupSlip';
export const CAN_VIEW_EDI = 'canViewEDI';
export const CAN_ADD_EDI = 'canAddEDI';
export const CAN_DOWNLOAD_EDI = 'canDownloadEDI';

export const CAN_VIEW_EMAIL_SCHEDULE = 'canViewEmailSchedule';
export const CAN_ADD_EMAIL_SCHEDULE = 'canAddEmailSchedule';
export const CAN_DELETE_EMAIL_SCHEDULE = 'canDeleteEmailSchedule';
export const CAN_VIEW_EDI_CONTAINER_REPORT = 'canViewEDIContainerReport';
export const CAN_VIEW_EDI_EMAIL_REPORT = 'canViewEDIEmailReport';
export const CAN_GENERATE_EDI = 'canGenerateEDI';

export const CAN_VIEW_GRADE = 'canViewGrade';
export const CAN_ADD_GRADE = 'canAddGrade';
export const CAN_EDIT_GRADE = 'canEditGrade';
export const CAN_DEACTIVATE_GRADE = 'canDeactivateGrade';
export const CAN_VIEW_CARGO = 'canViewCargo';
export const CAN_ADD_CARGO = 'canAddCargo';
export const CAN_EDIT_CARGO = 'canEditCargo';
export const CAN_VIEW_DASHBOARD_GRAPH = 'canViewDashboardGraph';
export const CAN_VIEW_SHIPPING_LINE_IN_OUT_GRAPH =
  'canViewShippingLineInOutGraph';
export const CAN_VIEW_DASHBOARD_GATED_IN_TILE = 'canViewDashboardGatedInTile';
export const CAN_VIEW_DASHBOARD_IN_PROCESS_TILE =
  'canViewDashboardInProcessTile';
export const CAN_VIEW_DASHBOARD_GATED_OUT_TILE = 'canViewDashboardGatedOutTile';
export const CAN_VIEW_DASHBOARD_READY_TILE = 'canViewDashboardReadyTile';
export const CAN_VIEW_DASHBOARD_PENDING_APPROVAL_TILE =
  'canViewDashboardPendingApprovalTile';
export const CAN_VIEW_DASHBOARD_ON_GROUND_TILE = 'canViewDashboardOnGroundTile';
export const CAN_VIEW_DASHBOARD_CRO_BALANCE_TILE =
  'canViewDashboardCROBalanceTile';
export const CAN_VIEW_DASHBOARD_RELEASABLE_TILE =
  'canViewDashboardReleasableTile';
export const CAN_VIEW_SHIPPING_LINE_DETAIL_TABLE =
  'canViewShippingLineDetailTable';
export const CAN_VIEW_ACTIVE_CRO_TABLE = 'canViewActiveCROTable';
export const CAN_QUICK_BIND_TRUCK_DRIVER_PICKUP_SLIP = 'canPickupSlipQuickBindTruckDriver';
export const CAN_VIEW_DASHBOARD_GATED_IN_CHART = 'canViewDashboardGatedInChart';
export const CAN_VIEW_DASHBOARD_GATED_OUT_CHART =
  'canViewDashboardGatedOutChart';
export const CAN_VIEW_DASHBOARD_EIR_PIE_CHART = 'canViewDashboardEIRPieChart';
export const CAN_VIEW_SUMMARY_REPORT = 'canViewSummaryReport';
export const CAN_VIEW_CONTAINER_HISTORY_REPORT =
  'canViewContainerHistoryReport';
export const CAN_VIEW_CUSTOMER_FASAH_REPORT = 'canViewCustomerFasahReport';
export const CAN_VIEW_CONTAINER_FASAH_REPORT = 'canViewContainerFasahReport';
export const CAN_VIEW_GATE_IN_REPORT = 'canViewGateInReport';
export const CAN_VIEW_GATE_OUT_REPORT = 'canViewGateOutReport';
export const CAN_VIEW_EACH_CRO_REPORT = 'canViewEachCroReport';
export const CAN_EMAIL_EACH_CRO_REPORT = 'canEmailEachCroReport';
export const CAN_VIEW_ALL_CRO_REPORT = 'canViewAllCroReport';
export const CAN_REVERT_CONTAINER = 'canRevertContainer';
export const CAN_ESTIMATE = 'canEstimate';
export const CAN_VIEW_PRICES = 'canViewPrices';
export const CAN_ASSIGN_ADDITIONAL_SERVICES = 'canAssignAdditionalServices';
export const CAN_APPROVE_SALE_UNIT = 'canApproveSaleUnit';
export const CAN_VIEW_SALE_UNIT = 'canViewSaleUnit';
export const CAN_REVERT_EIRIN = 'canRevertEIRIn';
export const CAN_VIEW_INVOICING = 'canViewInvoicing';
export const INVOICING_PERMISSION = 'invoicingPermission';
export const CAN_VIEW_GENERATE_INVOICE = 'canViewGenerateInvoice';
export const CAN_MARK_GENERATE_INVOICE = 'canMarkGenerateInvoice';
export const CAN_VIEW_INVOICES = 'canViewInvoices';
export const CAN_MARK_INVOICES_PAID = 'canMarkInvoicesPaid';
export const CAN_REVERT_INVOICE = 'canRevertInvoice';
export const CAN_VIEW_EIR_REPORT = 'canViewEIRReport';
export const CAN_VIEW_STOCK_REPORT = 'canViewStockReport';
export const CAN_VIEW_ON_GROUND_REPORT = 'canViewOnGroundReport';
export const CAN_VIEW_VEHICLE_OUT_REPORT = 'canViewVehicleOutReport';
export const CAN_VIEW_VEHICLE_IN_REPORT = 'canViewVehicleInReport';
export const CAN_CHANGE_GRADE = 'canChangeGrade';
export const CAN_MARK_SCRAP = 'canMarkScrap';
export const CAN_REVERT_SCRAP = 'canRevertScrap';

export const CAN_VIEW_REPORT_EMAIL_SCHEDULE = 'canViewReportEmailSchedule';
export const CAN_ADD_REPORT_EMAIL_SCHEDULE = 'canAddReportEmailSchedule';
export const CAN_DELETE_REPORT_EMAIL_SCHEDULE = 'canDeleteReportEmailSchedule';

export const CAN_VIEW_IN_APPOINTMENT = 'canViewInAppointment';
export const CAN_ADD_IN_APPOINTMENT = 'canAddInAppointment';
export const CAN_EDIT_IN_APPOINTMENT = 'canEditInAppointment';
export const CAN_REVERT_IN_APPOINTMENT = 'canRevertInAppointment';
export const CAN_LAPSE_IN_APPOINTMENT = 'canLapseInAppointment';

export const CAN_VIEW_OUT_APPOINTMENT = 'canViewOutAppointment';
export const CAN_ADD_OUT_APPOINTMENT = 'canAddOutAppointment';
export const CAN_EDIT_OUT_APPOINTMENT = 'canEditOutAppointment';
export const CAN_REVERT_OUT_APPOINTMENT = 'canRevertOutAppointment';
export const CAN_LAPSE_OUT_APPOINTMENT = 'canLapseOutAppointment';

export const FASAH_PAYMENTS_PERMISSION = 'fasahPaymentPermission';
export const CAN_VIEW_FASAH_PAYMENTS = 'canViewFasahPayments';
export const CAN_ADD_FASAH_PAYMENTS = 'canAddFasahPayments';
export const CAN_REVERT_FASAH_PAYMENTS = 'canRevertFasahPayments';

export const FASAH_DASHBOARD_PERMISSION = 'fasahDashboardPermission';
export const CAN_VIEW_FASAH_DASHBOARD_ACTIVE_IN_TILE = 'canViewFasahActiveIn';
export const CAN_VIEW_FASAH_DASHBOARD_USED_IN_TILE = 'canViewFasahUsedIn';
export const CAN_VIEW_FASAH_DASHBOARD_LAPSE_IN_TILE = 'canViewFasahLapseIn';
export const CAN_VIEW_FASAH_DASHBOARD_UNATTENDED_IN_TILE =
  'canViewFasahUnattendedIn';
export const CAN_VIEW_FASAH_DASHBOARD_ACTIVE_OUT_TILE = 'canViewFasahActiveOut';
export const CAN_VIEW_FASAH_DASHBOARD_USED_OUT_TILE = 'canViewFasahUsedOut';
export const CAN_VIEW_FASAH_DASHBOARD_LAPSE_OUT_TILE = 'canViewFasahLapseOut';
export const CAN_VIEW_FASAH_DASHBOARD_UNATTENDED_OUT_TILE =
  'canViewFasahUnattendedOut';
export const CAN_VIEW_PAYMENT_FASAH_GRAPH = 'canViewFasahGraph';
export const CAN_VIEW_PAYMENT_MAMAS_GRAPH = 'canViewPaymentToMamasGraph';
export const CAN_VIEW_PAYMENT_SHIPPING_LINE_GRAPH =
  'canViewPaymentShippingLineGraph';

export const CAN_VIEW_UNALLOCATED_TANKS = 'canViewUnAllocatedTanks';
export const CAN_MARK_UNALLOCATED_TANKS = 'canMarkUnAllocatedTanks';
export const CAN_UPLOAD_BULK_APPOINTMENTS = 'canUploadBulkAppointments';

export const CAN_ADD_UPDATE_BULK_SERVICES = 'canAddUpdateBulkServices';
export const CAN_ADD_UPDATE_BULK_GRADES = 'canAddUpdateBulkGrades';

export const EDI_DASHBOARD_PERMISSION = 'ediDashboardPermission';
export const CAN_VIEW_EDI_DASHBOARD_EMAIL_IN_TILE = 'canViewEdiEmailIn';
export const CAN_VIEW_EDI_DASHBOARD_EMAIL_OUT_TILE = 'canViewEdiEmailOut';
export const CAN_VIEW_EDI_DASHBOARD_CONTAINER_IN_TILE = 'canViewEdiContainerIn';
export const CAN_VIEW_EDI_DASHBOARD_CONTAINER_OUT_TILE =
  'canViewEdiContainerOut';
export const CAN_VIEW_EDI_DASHBOARD_SCHEDULE_DETAILS =
  'canViewEdiScheduleDetails';

export const containerViewImages = [
  CAN_EDIT_CONTAINER_IMAGES,
  CAN_VIEW_CONTAINER_IMAGES,
  CAN_DELETE_CONTAINER_IMAGES,
  CAN_ADD_CONTAINER_IMAGES
];
export const containerImagesCategory = [
  CAN_VIEW_IMAGE_CATEGORY,
  CAN_ADD_IMAGE_CATEGORY,
  CAN_EDIT_IMAGE_CATEGORY
];
export const containerView = [
  CAN_ADD_CONTAINER,
  CAN_EDIT_CONTAINER,
  CAN_DEACTIVATE_CONTAINER,
  CAN_VIEW_CONTAINER
];
export const containerTypeView = [
  CAN_ADD_CONTAINER_TYPE,
  CAN_EDIT_CONTAINER_TYPE,
  CAN_DEACTIVATE_CONTAINER_TYPE,
  CAN_VIEW_CONTAINER_TYPE
];
export const firmView = [
  CAN_ADD_FIRM,
  CAN_EDIT_FIRM,
  CAN_DEACTIVATE_FIRM,
  CAN_VIEW_FIRM
];
export const driverView = [
  CAN_ADD_DRIVER,
  CAN_EDIT_DRIVER,
  CAN_DEACTIVATE_DRIVER,
  CAN_VIEW_DRIVER
];
export const truckView = [
  CAN_ADD_TRUCK,
  CAN_EDIT_TRUCK,
  CAN_DEACTIVATE_TRUCK,
  CAN_VIEW_TRUCK
];
export const vesselAndVoyageView = [
  CAN_ADD_VESSEL_AND_VOYAGE,
  CAN_EDIT_VESSEL_AND_VOYAGE,
  CAN_DEACTIVATE_VESSEL_AND_VOYAGE,
  CAN_VIEW_VESSEL_AND_VOYAGE
];
export const arrivalInformationView = [
  CAN_ADD_ARRIVAL_INFORMATION,
  CAN_EDIT_ARRIVAL_INFORMATION,
  CAN_DEACTIVATE_ARRIVAL_INFORMATION,
  CAN_VIEW_ARRIVAL_INFORMATION
];
export const EIRInView = [
  CAN_ADD_EIRIN,
  CAN_EDIT_EIRIN,
  CAN_DEACTIVATE_EIRIN,
  CAN_VIEW_EIRIN
];
export const VehicleInView = [
  CAN_ADD_VEHICLE_IN,
  CAN_EDIT_VEHICLE_IN,
  CAN_VIEW_VEHICLE_IN
];
export const VehicleOutView = [
  CAN_ADD_VEHICLE_OUT,
  CAN_EDIT_VEHICLE_OUT,
  CAN_VIEW_VEHICLE_OUT
];
export const VehicleOutsideOutView = [
  CAN_ADD_VEHICLE_OUTSIDE_OUT,
  CAN_EDIT_VEHICLE_OUTSIDE_OUT,
  CAN_VIEW_VEHICLE_OUTSIDE_OUT
];
export const EIROutView = [
  CAN_ADD_EIROUT,
  CAN_EDIT_EIROUT,
  CAN_DEACTIVATE_EIROUT,
  CAN_VIEW_EIROUT
];
export const userView = [
  CAN_ADD_USER,
  CAN_EDIT_USER,
  CAN_DELETE_USER,
  CAN_VIEW_USER
];
export const roleView = [
  CAN_ADD_ROLE,
  CAN_EDIT_ROLE,
  CAN_DELETE_ROLE,
  CAN_VIEW_ROLE
];
export const CROView = [
  CAN_ADD_CRO,
  CAN_EDIT_CRO,
  CAN_DEACTIVATE_CRO,
  CAN_VIEW_CRO
];
export const serviceView = [
  CAN_ADD_SERVICE,
  CAN_EDIT_SERVICE,
  CAN_DEACTIVATE_SERVICE,
  CAN_VIEW_SERVICE
];
export const notifyView = [CAN_VIEW_CRO, CAN_VIEW_EIRIN, CAN_VIEW_EIROUT];
export const ediView = [CAN_VIEW_EDI, CAN_ADD_EDI];
export const reportsView = [
  CAN_VIEW_GATE_IN_REPORT,
  CAN_VIEW_GATE_OUT_REPORT,
  CAN_VIEW_SUMMARY_REPORT,
  CAN_VIEW_CONTAINER_HISTORY_REPORT,
  CAN_VIEW_EACH_CRO_REPORT,
  CAN_VIEW_ALL_CRO_REPORT,
  CAN_EMAIL_EACH_CRO_REPORT,
  CAN_VIEW_CUSTOMER_FASAH_REPORT,
  CAN_VIEW_CONTAINER_FASAH_REPORT,
  CAN_VIEW_VEHICLE_OUT_REPORT,
  CAN_VIEW_VEHICLE_IN_REPORT
];
export const staffManagementView = [
  CAN_VIEW_PERMISSION,
  CAN_VIEW_USER,
  CAN_VIEW_ROLE,
  CAN_VIEW_LOGIN_TOKEN
];
export const gradingsView = [
  CAN_ADD_GRADE,
  CAN_EDIT_GRADE,
  CAN_DEACTIVATE_GRADE,
  CAN_VIEW_GRADE
];
export const cargoView = [CAN_ADD_CARGO, CAN_EDIT_CARGO, CAN_VIEW_CARGO];
export const dashboardView = [
  CAN_VIEW_DASHBOARD_GRAPH,
  CAN_VIEW_SHIPPING_LINE_IN_OUT_GRAPH,
  CAN_VIEW_DASHBOARD_GATED_IN_TILE,
  CAN_VIEW_DASHBOARD_IN_PROCESS_TILE,
  CAN_VIEW_DASHBOARD_GATED_OUT_TILE,
  CAN_VIEW_DASHBOARD_READY_TILE,
  CAN_VIEW_DASHBOARD_PENDING_APPROVAL_TILE,
  CAN_VIEW_DASHBOARD_ON_GROUND_TILE,
  CAN_VIEW_DASHBOARD_CRO_BALANCE_TILE,
  CAN_VIEW_DASHBOARD_RELEASABLE_TILE,
  CAN_VIEW_SHIPPING_LINE_DETAIL_TABLE,
  CAN_VIEW_ACTIVE_CRO_TABLE
];

export const fasahDashboardView = [
  CAN_VIEW_PAYMENT_FASAH_GRAPH,
  CAN_VIEW_PAYMENT_MAMAS_GRAPH,
  CAN_VIEW_PAYMENT_SHIPPING_LINE_GRAPH,
  CAN_VIEW_FASAH_DASHBOARD_ACTIVE_IN_TILE,
  CAN_VIEW_FASAH_DASHBOARD_USED_IN_TILE,
  CAN_VIEW_FASAH_DASHBOARD_LAPSE_IN_TILE,
  CAN_VIEW_FASAH_DASHBOARD_UNATTENDED_IN_TILE,
  CAN_VIEW_FASAH_DASHBOARD_ACTIVE_OUT_TILE,
  CAN_VIEW_FASAH_DASHBOARD_USED_OUT_TILE,
  CAN_VIEW_FASAH_DASHBOARD_LAPSE_OUT_TILE,
  CAN_VIEW_FASAH_DASHBOARD_UNATTENDED_OUT_TILE
];
export const tileView = [
  CAN_VIEW_DASHBOARD_GATED_IN_TILE,
  CAN_VIEW_DASHBOARD_IN_PROCESS_TILE,
  CAN_VIEW_DASHBOARD_GATED_OUT_TILE,
  CAN_VIEW_DASHBOARD_READY_TILE,
  CAN_VIEW_DASHBOARD_PENDING_APPROVAL_TILE,
  CAN_VIEW_DASHBOARD_ON_GROUND_TILE,
  CAN_VIEW_DASHBOARD_CRO_BALANCE_TILE,
  CAN_VIEW_DASHBOARD_RELEASABLE_TILE
];

export const eirTileView = [
  CAN_VIEW_DASHBOARD_GATED_IN_TILE,
  CAN_VIEW_DASHBOARD_IN_PROCESS_TILE,
  CAN_VIEW_DASHBOARD_GATED_OUT_TILE,
  CAN_VIEW_DASHBOARD_READY_TILE,
  CAN_VIEW_DASHBOARD_PENDING_APPROVAL_TILE,
  CAN_VIEW_DASHBOARD_ON_GROUND_TILE
];

export const croView = [
  CAN_VIEW_DASHBOARD_CRO_BALANCE_TILE,
  CAN_VIEW_DASHBOARD_RELEASABLE_TILE
];

export const unAllocatedTanks = [
  CAN_VIEW_UNALLOCATED_TANKS,
  CAN_MARK_UNALLOCATED_TANKS
];

export const bulkSettings = [
  CAN_ADD_UPDATE_BULK_SERVICES,
  CAN_ADD_UPDATE_BULK_GRADES
];

export const ediDashboardView = [
  CAN_VIEW_EDI_DASHBOARD_EMAIL_IN_TILE,
  CAN_VIEW_EDI_DASHBOARD_EMAIL_OUT_TILE,
  CAN_VIEW_EDI_DASHBOARD_CONTAINER_IN_TILE,
  CAN_VIEW_EDI_DASHBOARD_CONTAINER_OUT_TILE,
  CAN_VIEW_EDI_DASHBOARD_SCHEDULE_DETAILS
];
